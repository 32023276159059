.note_header {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding16);
    margin-top: var(--padding16);
    font-size: var(--font14);
}
.note_export {
    padding: var(--padding6) var(--padding24);
    background-color: transparent;
    color: #333;
    font-size: var(--font13);
    border: 1px solid var(--butto-color);
    border-radius: var(--radius);
}
.note_td {
    width: 100%;
    margin-bottom: 6px;


}
.note_td>span {
    float: left;
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 2px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 13px;
    border-radius: 50%;
}
.note_td:last-child span {
    background-color: #666;
}

.problem_query {
    margin-top: var(--padding16);
    font-size: var(--font14);
}
