


.school_main {
    margin-top: var(--padding24);
}
.school_data_item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    margin-top: var(--padding24);
}
.school_data_item:last-child {
    margin-bottom: 48px;
}
.school_handle {
    display: flex;
    column-gap: 10px;
}
.school_btn {
    cursor: pointer;
    color: #1677ff;
}
.school_btn:hover {
    color: rgba(22, 119, 255, .7);
}
