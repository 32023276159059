.login_content {
    width: 100%;
    height: 100vh;
    background-color: #f7f7f7;
    background-image: url('../image/login-bg.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.login_logo {
    width: 128px;
}
.login_form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: calc(100% - 60px);
    padding-right: 64px;
}

.login_form_main {
    width: 420px;
    padding: 80px 48px 100px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 1px 1px;
    background-color: #fff;
}
.login_form_title {
    width: calc(100% - 96px);
    margin: 0 auto 36px;
    font-size: 24px;
    text-align: center;
}
.login_form_tootip {
    font-size: var(--font13);
    text-align: center;
    color: #999;
}
