
.layout_content {
    display: flex;
    width: 100%;
    height: 100vh;
  
}
.layout_left {
    width: 248px;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid var(--border-color);
}
.layout_left_header {
    padding: var(--padding6) var(--padding24);
}
.layout_left_header_logo {
    width: 110px;
    margin-left: 18px;
}

.layout_sidebar {
    padding: var(--padding24);
    border-top: 1px solid var(--border-color);
}
.layout_sidebar_item {
    margin-bottom: 20px;
}

.layout_main {
    width: calc(100% - 248px);
    min-width: 748px;
    height: 100%;
    padding: var(--padding24);
}
.layout_sidebar_item_title {
    margin-bottom: 4px;
    color: #000;
}
.layout_sidebar_item_subtitle {
    display: flex;
    column-gap: var(--padding6);
    padding: 8px 12px;
    margin-bottom: 4px;
    border-radius: var(--radius);
    font-size: 14px;
    color: #666;
    cursor: pointer;
}
.layout_sidebar_item_subtitle:not(.layout_sidebar_item_active):hover {
    background-color: rgba(var(--bg-color), .6);
}
.layout_sidebar_item_active {
    background-color: rgb(var(--bg-color));
}


