@import './assets/styles/login';
@import './assets/styles/layout';
@import './assets/styles/school';
@import './assets/styles/inter';

:root {
    --border-color: #ebebec;
    --bg-color: 242, 242, 242;
    --butto-color: #313539;
    --padding6: 6px;
    --padding16: 16px;
    --padding24: 24px;
    --font13: 13px;
    --font14: 14px;
    --radius: 6px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5,h6,ul,p {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
div {
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
}
button {
  border: 0;
  outline: none;
  cursor: pointer;
}

.common_content {
    width: calc(100% - 120px);
    height: 100%;
    margin: 0 auto;
}
.common_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.common_btn {
    padding: var(--padding6) var(--padding24);
    background-color: var(--butto-color);
    color: #fff;
    font-size: var(--font13);
    border-radius: var(--radius);
}
